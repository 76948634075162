import React from 'react';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1>Willkommen bei Samichlous Belp!</h1>
        <p>Dies ist eine Testseite für den Samichlous-Belp.</p>
        <p>Diese Seite wird auf Netlify gehostet.</p>
      </header>
    </div>
  );
}

export default App;
